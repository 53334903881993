<template>
    <div class="page2">
        <Coldiv-red direction="left">
            <div style="margin-top: 3.23vh">
                <Subtitle-red text="社区党建"></Subtitle-red>
                <div class="dangjianBox">
                    <div class="dangjianInfo">
                        <div class="dangjianIconBox">
                            <img src="@/assets/page1/党员.png" class="dangjianIcon" alt="">
                        </div>
                        <div class="dangjianTextBox">
                            <div class="dangjianText">党员数量</div>
                            <active-number class="dangjianValue" :number="partyMembers"></active-number>
                        </div>
                    </div>
                    <div class="dangjianInfo">
                        <div class="dangjianIconBox">
                            <img src="@/assets/page1/党建活动.png" class="dangjianIcon" alt="">
                        </div>
                        <div class="dangjianTextBox">
                            <div class="dangjianText">党建活动</div>
                            <active-number class="dangjianValue" :number="partyEvents"></active-number>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 2.59vh">
                <Subtitle-red text="党员概况"></Subtitle-red>
                <!--<div class="legendBox">
                    <div class="legend">
                        <div class="colorBlock" style="background: #ff3b3b"></div>
                        <div class="legendText">30岁以下</div>
                    </div>
                    <div class="legend">
                        <div class="colorBlock" style="background: #ffd03b"></div>
                        <div class="legendText">30-50岁</div>
                    </div>
                    <div class="legend">
                        <div class="colorBlock" style="background: #d8e6fd"></div>
                        <div class="legendText">50岁以上</div>
                    </div>
                </div>-->
                <sub-section-bar class="subSection" style="margin-top: 2.64vh" itemName="机关党员" :dataInput="partyMemberType.d1" :biggest="partyMemberType.max"></sub-section-bar>
                <!--<sub-section-bar class="subSection" style="margin-top: 0.37vh" itemName="两新党员" :dataInput="partyMemberType.d2" :biggest="partyMemberType.max"></sub-section-bar>
                <sub-section-bar class="subSection" style="margin-top: 0.37vh" itemName="流动党员" :dataInput="partyMemberType.d3" :biggest="partyMemberType.max"></sub-section-bar>-->
                <sub-section-bar class="subSection" style="margin-top: 0.37vh" itemName="银龄党员" :dataInput="partyMemberType.d4" :biggest="partyMemberType.max"></sub-section-bar>
                <sub-section-bar class="subSection" style="margin-top: 0.37vh" itemName="困弱党员" :dataInput="partyMemberType.d5" :biggest="partyMemberType.max"></sub-section-bar>
                <sub-section-bar class="subSection" style="margin-top: 0.37vh" itemName="退役军党" :dataInput="partyMemberType.d6" :biggest="partyMemberType.max"></sub-section-bar>
            </div>
            <div style="margin-top: 2.59vh">
                <Subtitle-red text="发展党员"></Subtitle-red>
                <div class="redCircleBox">
                    <red-circle class="redCircle" :itemName="recruitingMembers.length?recruitingMembers[0].typeName:'加载中'" :itemValue="recruitingMembers.length?recruitingMembers[0].total:0"></red-circle>
                    <red-circle class="redCircle" :itemName="recruitingMembers.length?recruitingMembers[1].typeName:'加载中'" :itemValue="recruitingMembers.length?recruitingMembers[1].total:0"></red-circle>
                    <red-circle class="redCircle" :itemName="recruitingMembers.length?recruitingMembers[2].typeName:'加载中'" :itemValue="recruitingMembers.length?recruitingMembers[2].total:0"></red-circle>
                    <red-circle class="redCircle" :itemName="recruitingMembers.length?recruitingMembers[3].typeName:'加载中'" :itemValue="recruitingMembers.length?recruitingMembers[3].total:0"></red-circle>
                </div>
            </div>
            <div style="margin-top: 2.59vh">
                <Subtitle-red text="党费缴纳"></Subtitle-red>
                <div class="memberDuesBox" style="margin-top: 1.85vh">
                    <div class="ringBox">
                        <div class="ringGroupBox">
                            <img src="@/assets/page2/group.png" class="ringGroup" alt="">
							<active-number class="mainValue" :number="yingjiaoMembers"></active-number>
                        </div>
                        <div class="outerText">应缴人数(人)</div>
                    </div>
                    <div class="outerDataBox">
                        <div class="innerDataBox">
							<active-number class="dataValue" :number="yijiaoMembers"></active-number>
                            <div class="dataName">实缴人数</div>
                        </div>
                        <div class="divideBar"></div>
                        <div class="innerDataBox">
							<active-number class="dataValue" :number="yingjiaoMembers-yijiaoMembers"></active-number>
                            <div class="dataName">未缴人数</div>
                        </div>
                        <div class="divideBar"></div>
                        <div class="innerDataBox">
                            <span class="dataValue">{{yingjiaoMembers?(yijiaoMembers*100/yingjiaoMembers).toFixed(1):'0.0'}}%</span>
                            <div class="dataName">实缴比例</div>
                        </div>
                    </div>
                </div>
                <div class="memberDuesBox" style="margin-top: 1.48vh">
                    <div class="ringBox">
                        <div class="ringGroupBox">
                            <img src="@/assets/page2/group.png" class="ringGroup" alt="">
							<active-number class="mainValue" :number="yingjiaoDues"></active-number>
                        </div>
                        <div class="outerText">应缴党费(元)</div>
                    </div>
                    <div class="outerDataBox">
                        <div class="innerDataBox">
                            <active-number class="dataValue" :number="yijiaoDues"></active-number>
                            <div class="dataName">实缴金额</div>
                        </div>
                        <div class="divideBar"></div>
                        <div class="innerDataBox">
                            <active-number class="dataValue" :number="yingjiaoDues-yijiaoDues"></active-number>
                            <div class="dataName">未缴金额</div>
                        </div>
                        <div class="divideBar"></div>
                        <div class="innerDataBox">
                            <span class="dataValue">{{yingjiaoDues?(yijiaoDues*100/yingjiaoDues).toFixed(1):'0.0'}}%</span>
                            <div class="dataName">实缴比例</div>
                        </div>
                    </div>
                </div>
            </div>
        </Coldiv-red>
        <Coldiv-red direction="right">
            <div style="margin-top: 3.23vh">
                <subtitle-red text="党员风采"></subtitle-red>
                <div class="memberPreviewBox" v-if="!memberProfile.length">{{MemberListLoadingStatus}}</div>
				<Scrollview class="memberPreviewBox" v-else>
					<person-profile v-for="(item, index) in memberProfile" :dataInput="item" :key="index" style="margin-bottom: 1.85vh"></person-profile>
				</Scrollview>
            </div>
        </Coldiv-red>

    </div>
</template>

<script>
const ColdivRed = () => import('./components/coldivRed.vue');
const SubtitleRed = () => import('./components/subtitleRed.vue');
const subSectionBar = () => import('./components/subSectionBar.vue');
const redCircle = () => import('./components/redCircle.vue');
const personProfile = () => import('./components/personProfile.vue');
const Scrollview = () => import('./components/scrollview.vue');
const activeNumber = () => import('./components/activeNumber.vue');

export default {
    name: 'page2',
    components: {
        ColdivRed,
        SubtitleRed,
        subSectionBar,
        redCircle,
        personProfile,
		Scrollview,
        activeNumber
    },
    data(){
        return {
            //模拟数据，输入到subSectionBar组件
			pollingSIV: null,
            partyMemberType:{
                max: 0,
                d1:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d2:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d3:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d4:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d5:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d6:{
                    young: 0,
                    middle: 0,
                    elder: 0
                }
                
            },
			recruitingMembers:[],
			memberProfile: [],
			intervalId: null,
            partyMembers: 0,
            partyEvents: 0,
            MemberListLoadingStatus: '加载中...',
			yingjiaoMembers: 0,
			yijiaoMembers: 0,
			yingjiaoDues: 0,
			yijiaoDues: 0
        }
    },
    methods: {
        //获取预备党员信息
		getRecruitingMembers(){
			let sl = ['发展对象','入党申请人','入党积极分子','新发展党员'];
			let taskList = [];
			let that = this;
			for(let i = 0;i < sl.length;i++){
				taskList.push(new Promise((resolve, reject) => {
					that.$api.getRecruitingMembers({
						type: sl[i],
						pageSize: 1
					}).then(res => {
						resolve(res);
					}).catch(err => {
						reject(err);
					})
				}))
			}
			let pm = Promise.all(taskList);
			pm.then(arr => {
				let o = [];
				for(let j = 0;j < 4;j++){
					o.push({
						typeName: sl[j],
						total: arr[j].data.total
					})
				}
				this.recruitingMembers = o;
			}).catch(err => {
				console.log(err);
			});
		},
        //获取党员信息：当数据为空时，展示虚拟数据，不要显示加载中
        getPartyMembers(){
            this.$api.getPartyMembers({
                pageSize: 9999999
            }).then(res => {
                let d = res.data.rows;
                if(d.length){
                    //数据不为空时，处理真实数据。否则，去请求虚拟数据并处理
                    this.handlePartyMemberInfo(res);
                }else{
                    this.$api.getPartyMembers({
                        mock: true
                    }).then(res => {
                        this.handlePartyMemberInfo(res);
                    }).catch(err => {
                        console.log(err);
                    })
                }
            }).catch(err => {
                console.log(err);
                this.MemberListLoadingStatus = '加载失败，请检查网络连接！'
            })
        },
        //加载党员列表并处理党员信息
        handlePartyMemberInfo(res){
            let d = res.data.rows;
            let o = [];
            this.partyMembers = res.data.total;
            this.partyEvents = 0;//暂时先用假数据
            for(let i = 0;i < d.length;i++){
                let obj = {
                    memberName: d[i].name,
                    avatarUrl: d[i].photo,
                    scores: {
                        theoryLevel: d[i].theoreticalLevel,
                        dedication: d[i].dedicationSense,
                        behavior: d[i].dailyBehavior,
                        exemplar: d[i].exemplaryRole,
                        belief: d[i].idealsAndBeliefs,
                        ability: d[i].businessCapability
                    }
                }
                o.push(obj);
            }
            this.memberProfile = o;
            //将列表中的党员分类后统计每类型各年龄段党员
            let max = 0;
            let tp = {
                d1:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d2:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d3:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d4:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d5:{
                    young: 0,
                    middle: 0,
                    elder: 0
                },
                d6:{
                    young: 0,
                    middle: 0,
                    elder: 0
                }
            };
            for(let j = 0;j < d.length;j++){
                let p = d[j];
                let tn = '';
                let age = this.$u.getAge(p.birthday);
                switch(p.type){
                    case '机关党员':
                        tn = 'd1';
                        break;
                    case '两新党员':
                        tn = 'd2';
                        break;
                    case '流动党员':
                        tn = 'd3';
                        break;
                    case '银龄党员':
                        tn = 'd4';
                        break;
                    case '困弱党员':
                        tn = 'd5';
                        break;
                    case '退役军党':
                        tn = 'd6';
                        break;
                }
                if(tn){
                    if(age < 30){
                        tp[tn].young++;
                    }else if(age >= 30 && age <= 50){
                        tp[tn].middle++;
                    }else{
                        tp[tn].elder++;
                    }
                }
            }
            let lt = ['d1','d2','d3','d4','d5','d6'];
            for(let k = 0;k < lt.length;k++){
                let ek = tp[lt[k]];
                let sum = ek.young + ek.middle + ek.elder;
                max = sum > max ? sum : max;
            }
            tp.max = max;
            this.partyMemberType = tp;
        },
        //获取党费缴纳情况数据
		getPartyDue(){
			this.$api.getPartyDue({
				pageSize: 999999
			}).then(res => {
				let list = res.data.rows;
				let yingjiaoMembers = 0;
				let yijiaoMembers = 0;
				let yingjiaoDues = 0;
				let yijiaoDues = 0;
				for(let i = 0;i < list.length;i++){
					yingjiaoMembers++;
					yingjiaoDues += list[i].amount;
					if(list[i].status == '已缴费'){
						yijiaoMembers++;
						yijiaoDues += list[i].amount;
					}
				}
				this.yingjiaoMembers = yingjiaoMembers;
				this.yijiaoMembers = yijiaoMembers;
				this.yingjiaoDues = yingjiaoDues;
				this.yijiaoDues = yijiaoDues;
			}).catch(err => {
				console.log(err);
			})
		},
        //获取党建活动场数
		getPartyActivities(){
			this.$api.getPartyActivity().then(res => {
				let d = res.data.data;
				let count = d.activityNumber + d.studyNumber;
				this.partyEvents = count;
			}).catch(err => {
				console.log(err);
			})
		},
        //页面初始化
		updatePageData(){
			this.getPartyMembers();
			this.getRecruitingMembers();
			this.getPartyDue();
			this.getPartyActivities();
		}
	},
    watch: {
    },
    mounted() {
		this.$eventBus.$emit('switchPage', 2);
        this.updatePageData();
		this.pollingSIV = setInterval(() => {
			this.updatePageData();
		}, this.$c.pollingInterval)
    },
	beforeDestroy(){
		clearInterval(this.pollingSIV);
	}

}
</script>

<style lang="less" scoped>
@import "~@/assets/less/index.less";
.page2 {
    height: 100%;
    width: 100%;
    background: none;
    overflow: hidden;
    display: flex;
	justify-content: space-between;
	transform: translateY(-1.11vh);
	z-index: 10;

    .dangjianBox {
        margin-top: 1.85vh;
        margin-left: 4.76%;
        width: 90.48%;
        height: 7.41vh;
        display: flex;
        justify-content: space-between;

        .dangjianInfo {
            height: 100%;
            width: 47.36%;
            display: flex;
            align-items: center;

            .dangjianIconBox {
                width: 45.56%;
                height: 85%;

                .dangjianIcon {
                    width: 100%;
                    height: 100%;
                }
            }
            .dangjianTextBox {
                height: 78.57%;
                margin-left: 5.56%;

                .dangjianText {
                    font-family: 'pingfangSc', sans-serif;
                    height: 1.944vh;
                    font-size: 1.3vh;
                    line-height: 1.3vh;
					display: flex;
					align-items: center;
                    color: rgba(255,255,255,0.7);
					vertical-align: bottom;
					margin-top: 21.25%;
                }

                .dangjianValue {
                    font-family: 'titleFont', sans-serif;
                    background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    line-height: 1.85vh;
					font-size: 1.85vh;
                    
                }
            }
        }
    }
    .legendBox {
        width: 62.38%;
        margin-left: 20.24%;
        height: 1.944vh;
        display: flex;
        justify-content: space-between;
        margin-top: 1.85vh;

        .legend {
            width: 27.48%;
            height: 100%;
            display: flex;
            align-items: center;

            .colorBlock {
                height: 38.1%;
                aspect-ratio: 1/1;
                margin-right: 6.94%;
            }

            .legendText {
                height: 1.944vh;
                font-size: 1.3vh;
                line-height: 1.944vh;
                font-family: 'pingfangSc', sans-serif;
                color: rgba(255,255,255,0.7);
            }

        }
    }
    .subSection {
        width: 90.48%;
        height: 1.944vh;
        margin-left: 4.76%;
        overflow: visible;
    }
    
    .redCircleBox {
        width: 97.14%;
        margin-left: 1.43%;
        height: 8.24vh;
        margin-top: 1.85vh;
        display: flex;
        .redCircle {
            width: 25%;
        }
    }

    .memberDuesBox {
        width: 90%;
        height: 10.185vh;
        margin-left: 4.76%;
        margin-top: 1.85vh;
        position: relative;

        .ringBox {
            width: 22.37%;
            height: 100%;

            .ringGroupBox {
                width: 100%;
                aspect-ratio: 1/1;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .mainValue {
                    height: 2.13vh;
                    font-family: 'titleFont', sans-serif;
                    font-size: 1.85vh;
                    line-height: 2.13vh;
                    font-weight: 400;
                    /*text-shadow: 0px 0px 9px rgba(255,59,59,0.7);*/
                    background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                }

                .ringGroup {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transform: scale(2);
                    transform-origin: 50% 50%;
                }

            }

            
            .outerText {
                width: 100%;
                height: 1.944vh;
                text-align: center;
                font-family: 'pingfangSc', sans-serif;
                color: #fff;
                font-size: 1.3vh;
                line-height: 180%;

            }
        }

        .outerDataBox {
            position: absolute;
            width: 73.684%;
            height: 59.82%;
            top: 19.64%;
            right: 0;
            background: linear-gradient(45deg, #301615 0%, rgba(178,0,0,0) 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(255, 59, 59, 0.4), rgba(255, 59, 59, 0)) 1 1;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .innerDataBox {
                height: 67.164%;
                width: 22.86%;
                text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				

                .dataValue {
                    height: 2.13vh;
                    font-size: 1.85vh;
                    line-height: 2.13vh;
                    font-family: 'titleFont', sans-serif;
                    font-weight: 400;
                    background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    letter-spacing: 1px;
					vertical-align: top;

                }
                .dataName {
                    height: 2.04vh;
                    font-size: 1.3vh;
                    line-height: 2.04vh;
                    color: rgba(255,255,255,0.7);
                    text-align: center;
					vertical-align: top;

                }
            }
            .divideBar {
                width: 0;
                height: 304.48%;
                border-left: 0.5px;
                border-style: solid;
                border-image: radial-gradient(circle, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
                transform: scale(0.25);
                transform-origin: 0 50%;
				opacity: 0.6;
            }

        }

    }

    .memberPreviewBox {
        height: 81.11vh;
        width: 90.48%;
        margin-left: 4.76%;
        margin-top: 1.944vh;
		position: relative;
        color: #fff;
        font-size: 1.85vh;
        font-family: 'pingfangSc', sans-serif;
		
		.memberList {
			position: absolute;
			width: 100%;
		}
    }

}
</style>
